import React, { useState } from "react";
// import { GET_SCHOOLS } from "../../../../../Qurries";
import { useQuery } from "@apollo/client";
// import CheckBoxComponent from "./CheckBoxComponent";
import { handleCheckboxChange } from "../../Transaction";
import { CustomDropdownIndicator } from "../../../Settlement/Settlement";
import Select from "react-select";

function Institute({ setSelectSchool, setSchoolId }: any) {
  // const { data } = useQuery(GET_SCHOOLS);

  // const schoolsList = data?.getSchoolQuery?.schools.map((school: any) => {
  //   return {
  //     label: school.school_name,
  //     value: school.school_name,
  //     id: school.school_id,
  //   };
  // });

  return (
    <div>
      <div className="flex flex-col w-full">
        <Select
          className="font-normal m-0 capitalize"
          options={[]}
          components={{
            DropdownIndicator: CustomDropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          onChange={(e: any) => {
            setSchoolId(e.id);
            setSelectSchool(e.value);
            // refetchDataFetch({
            //   start_date: isDateRangeIsSelected
            //     ? formatDate(selectedRange.startDate)
            //     : startDate,
            //   end_date: isDateRangeIsSelected
            //     ? formatDate(selectedRange.endDate)
            //     : endDate,
            //   status: status?.toUpperCase(),
            //   school_id: e.id,
            // });
            // setCurrentPage(1);
          }}
          placeholder={
            <div className="text-[#1E1B59] text-xs">Select Institute</div>
          }
          value={null}
          styles={{
            control: (provided: any) => ({
              ...provided,
              backgroundColor: "#F6F8FA",
              border: "1px solid #1B163B",
              borderRadius: "6px",

              minHeight: "15px",
              margin: "0px",
              color: "#6687FF",
            }),
            valueContainer: (provided: any) => ({
              ...provided,
              padding: "0px",
              paddingLeft: "0.5rem",
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: "200px",
            }),
            input: (provided: any) => ({
              ...provided,
              backgroundColor: "transparent",
              color: "#000",

              "::placeholder": {
                backgroundColor: "#YourDesiredColor",
                opacity: 1,
              },
              placeholder: (provided: any) => ({
                ...provided,
                color: "red",
              }),
            }),
          }}
        />
      </div>
    </div>
  );
}

export default Institute;
