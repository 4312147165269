import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { GET_USER, GET_VENDORS } from "../../../Qurries";
import { endOfDay, startOfDay } from "date-fns";
import {
  _Table,
  Pagination,
  RowsPerPageSelect,
} from "../../../components/Table";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import RateChargeModal from "../../../components/Modal/RateChargeModal";
import VendorStepForm from "./VendorStepForm";
import { HiMiniXMark } from "react-icons/hi2";
import Select from "react-select";
import { getStartAndEndOfMonth } from "../../../utils/getStartDateAndEndDate";
import { CustomDropdownIndicator } from "../Settlement/Settlement";
import TransactionDateFilter, {
  formatDate,
} from "../Transaction/components/TransactionDateFilter";

function VendorList() {
  const [showVender, setShowVender] = useState(false);

  const [limit, setLimit] = useState({ name: 10 });

  const [searchFilter, setSearchFilter] = useState<any>("");
  const [settlementData, setSettlementData] = useState<any>([]);
  const [isDateRangeIsSelected, setIsDateRangeIsSelected] = useState(false);
  const [type, setType] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [status, setStatus] = useState<any>(null);
  const [schoolId, setSchoolId] = useState<string>("");
  const [selectSchool, setSelectSchool] = useState("");
  const [refetching, setRefetching] = useState(false);
  const { startDate, endDate } = getStartAndEndOfMonth();
  const [searchText, setSearchText] = useState<any>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerRow, setItemsPerRow] = useState({ name: 10 });
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const { data, loading, refetch } = useQuery(GET_VENDORS, {
    variables: {
      page: currentPage,
      limit: limit.name,
      // startDate,
      // endDate,
    },
  });

  const refetchDataFetch = async ({
    // startDate,
    // endDate,
    page,
    status,
    vendor_id,
  }: // utr,
  {
    // startDate?: any;
    // endDate?: any;
    page?: String;
    status?: String;
    limit?: String;
    vendor_id?: string;
    // utr?: string;
  }) => {
    try {
      setRefetching(true);
      const data = await refetch({
        // endDate,
        // startDate,
        page: currentPage,
        limit: limit.name,
        status,
        vendor_id,
        // utr,
      });
      if (data?.data?.GetMerchantVendor) {
        console.log(data?.data?.GetMerchantVendor);
        setRefetching(false);
        setSettlementData(data?.data?.GetMerchantVendor?.vendors);
      }
    } catch (error) {
      setRefetching(false);
    } finally {
      setRefetching(false);
    }
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-4">
      <RateChargeModal
        title="Vendor Information"
        open={showVender}
        setOpen={setShowVender}
      >
        <VendorStepForm setShowVender={setShowVender} />
      </RateChargeModal>

      {loading ? (
        <div className="min-h-[80vh] w-full flex justify-center items-center">
          <AiOutlineLoading3Quarters className="text-3xl animate-spin" />
        </div>
      ) : (
        <_Table
          exportBtn={false}
          perPage={false}
          srNo={false}
          heading={"Vendor List"}
          copyContent={[3]}
          filter={[searchText]}
          searchBox={
            <div className="w-full ">
              <div className="flex xl:!flex-row flex-col justify-between gap-2  w-full xl:items-center items-start mb-2">
                <div className="bg-[#EEF1F6] py-3 items-center flex  px-3 xl:max-w-md max-w-[34rem] w-full rounded-lg">
                  <input
                    className="text-xs pr-2 bg-transparent focus:outline-none w-full placeholder:font-normal"
                    type="text"
                    value={searchText}
                    placeholder="Search(Order ID...)"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  {searchFilter !== "" && searchText.length > 3 && (
                    <HiMiniXMark
                      onClick={async () => {
                        setSearchFilter("");
                        setSearchText("");
                        refetchDataFetch({
                          // startDate: startDate,
                          // endDate: endDate,
                        });
                      }}
                      className="text-[#1E1B59] cursor-pointer text-md mr-2 shrink-0"
                    />
                  )}
                  <Select
                    className="border-l-2 border-gray-400"
                    options={[
                      // {
                      //   label: "By UTR",
                      //   value: "utr",
                      // },
                      { label: "By Vendor ID", value: "vendor_id" },
                    ]}
                    isSearchable={false}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e: any) => {
                      setSearchFilter(e.value.toLowerCase());
                      setCurrentPage(1);
                    }}
                    placeholder={
                      <div className="text-[#1E1B59] -mt-1 capitalize text-[10px]">
                        {searchFilter === ""
                          ? "filter by"
                          : searchFilter
                              .toString()
                              .toLowerCase()
                              .replaceAll("_", " ") === "utr"
                          ? "By UTR"
                          : searchFilter
                              .toString()
                              .toLowerCase()
                              .replaceAll("_", " ")}
                      </div>
                    }
                    value={searchFilter}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "transparent",
                        height: "20px",
                        border: "none",
                        boxShadow: "none",
                        cursor: "pointer",
                        minHeight: "10px",
                        padding: "0px",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        height: "20px",
                        width: "8rem",
                        padding: "0 8px",
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: "0",
                        padding: "0",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        margin: "0",
                        padding: "0",
                        lineHeight: "20px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        lineHeight: "20px",
                      }),
                      indicatorsContainer: (provided) => ({
                        ...provided,
                        height: "20px",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: "10px",
                        cursor: "pointer",
                      }),
                    }}
                  />

                  <div className="w-10 z-50 shrink-0 flex justify-center items-center">
                    {searchText.length > 3 && refetching ? (
                      <AiOutlineLoading3Quarters className="text-xs animate-spin" />
                    ) : (
                      <i
                        onClick={() => {
                          if (searchText.length > 3 && searchFilter !== "") {
                            setCurrentPage(1);
                            refetchDataFetch({
                              vendor_id:
                                searchFilter === "vendor_id"
                                  ? searchText
                                  : null,
                              // utr: searchFilter === "utr" ? searchText : null,
                            });
                          }
                        }}
                        className=" cursor-pointer text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"
                      ></i>
                    )}
                  </div>
                </div>

                <div className="flex justify-end items-center flex-1 w-full max-w-[26rem]">
                  {/* <TransactionDateFilter
                    setType={setDateRange}
                    type={dateRange}
                    refetch={() => {
                      refetchDataFetch({
                        // startDate: formatDate(selectedRange.startDate),
                        // endDate: formatDate(selectedRange.endDate),
                        // school_id: schoolId === "" ? null : schoolId,
                      });
                    }}
                    selectedRange={selectedRange}
                    setSelectedRange={setSelectedRange}
                    setIsDateRangeIsSelected={setIsDateRangeIsSelected}
                  /> */}

                  {/* <div className="w-full ml-2">
                    <Institute
                      setSelectSchool={setSelectSchool}
                      setSchoolId={setSchoolId}
                    />
                  </div> */}
                </div>
              </div>
              <div>
                <RowsPerPageSelect
                  setItemsPerRow={setItemsPerRow}
                  itemsPerRow={itemsPerRow}
                  className=" justify-start"
                />
              </div>
              <div className="flex items-center">
                {/* {type !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setSelectedRange({
                          startDate: startOfDay(new Date()),
                          endDate: endOfDay(new Date()),
                          key: "selection",
                        });
                        if (status || schoolId) {
                          setType("");
                          refetchDataFetch({
                            startDate: startDate,
                            endDate: endDate,
                            status: status?.toUpperCase(),
                            // school_id: schoolId === "" ? null : schoolId,
                          });

                          setIsDateRangeIsSelected(false);
                        } else {
                          refetchDataFetch({
                            startDate: startDate,
                            endDate: endDate,
                          });
                          setType("");
                          setIsDateRangeIsSelected(false);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {type} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )} */}
                {/* {dateRange !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setSelectedRange({
                          startDate: startOfDay(new Date()),
                          endDate: endOfDay(new Date()),
                          key: "selection",
                        });
                        if (status || schoolId) {
                          refetchDataFetch({
                            startDate: startDate,
                            endDate: endDate,
                            status: status?.toUpperCase(),
                            // school_id: schoolId === "" ? null : schoolId,
                          });
                          setDateRange("");
                          setIsDateRangeIsSelected(false);
                        } else {
                          refetchDataFetch({
                            startDate: startDate,
                            endDate: endDate,
                          });
                          setDateRange("");
                          setIsDateRangeIsSelected(false);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {dateRange} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )} */}
                {/* {selectSchool !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={() => {
                        if (status || isDateRangeIsSelected || type) {
                          refetchDataFetch({
                            startDate: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            endDate: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            status: status?.toUpperCase(),
                          });
                          setSelectSchool("");
                          setSchoolId("");
                        } else {
                          refetchDataFetch({
                            startDate: startDate,
                            endDate: endDate,
                          });
                          setSelectSchool("");
                          setSchoolId("");
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {selectSchool} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )} */}
                {/* {status && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setCurrentPage(1);
                        if (selectSchool || isDateRangeIsSelected) {
                          refetchDataFetch({
                            startDate: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            endDate: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            // school_id: schoolId === "" ? null : schoolId,
                          });
                          setStatus(null);
                        } else {
                          refetchDataFetch({
                            startDate: startDate,
                            endDate: endDate,
                          });
                          setStatus(null);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {status} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          }
          footer={
            <div className="flex justify-center items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={data?.getMerchantVendor?.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          }
          data={[
            [
              "Sr.No",
              "Vendor Name",
              "Vendor ID",
              "Status",
              "Account holder Name",
              "Account Number",
              "IFSC Code",
              "Creation Date",
            ],
            ...(data?.getMerchantVendor?.vendors.map(
              (Vendor: any, index: number) => [
                <div>
                  {currentPage === 1
                    ? index + 1
                    : (currentPage - 1) * limit.name + index + 1}
                </div>,
                <div>{Vendor.name}</div>,
                <div>{Vendor._id}</div>,
                <div
                // className={
                //   "cursor-pointer " +
                //   (Vendor.status.toLowerCase() === "active"
                //     ? " text-green-500"
                //     : Vendor.status.toLowerCase() === "inactive"
                //       ? "text-gray-500"
                //       : Vendor.status.toLowerCase() === "suspended"
                //         ? "text-red-500"
                //         : "")
                // }
                >
                  {Vendor?.status}
                </div>,
                <div>{Vendor?.bank_details?.account_holder}</div>,
                <div>{Vendor?.bank_details?.account_number}</div>,
                <div>{Vendor?.bank_details?.ifsc}</div>,
                <div>{new Date(Number(Vendor.createdAt)).toDateString()}</div>,
              ]
            ) || []),
          ]}
        />
      )}
    </div>
  );
}

export default VendorList;
