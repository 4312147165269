/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from "react";
import { IoSearch } from "react-icons/io5";
import Filters from "./components/Filters";
import { FaX } from "react-icons/fa6";
// import EdvironLogo from "../../../../assets/EdvironLogo.svg";
import { useQuery } from "@apollo/client";
import {
  _Table,
  Pagination,
  RowsPerPageSelect,
} from "../../../components/Table";
import { GET_TRANSACTION_REPORT } from "../../../Qurries";
import { dashboardContext } from "../Dashboard";
import { Link } from "react-router-dom";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { getStartAndEndOfMonth } from "../../../utils/getStartDateAndEndDate";
import { HiMiniXMark } from "react-icons/hi2";
import { CustomDropdownIndicator } from "../Settlement/Settlement";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import TransactionDateFilter, {
  formatDate,
} from "./components/TransactionDateFilter";
import MixFilter from "./components/MixFilter";
import Select from "react-select";
import { getPaymentMode } from "../../../utils/getPaymentMode";
import { endOfDay, startOfDay } from "date-fns";
import axios from "axios";
export const handleCheckboxChange = (
  category: any,
  option: any,
  setFilters: any
) => {
  setFilters((prevFilters: any) => ({
    ...prevFilters,
    [category]: {
      ...prevFilters[category],
      [option]: !prevFilters[category][option],
    },
  }));
};
export const payment_method_map: any = {
  credit_card: "Credit Card",
  debit_card: "Debit Card",
  net_banking: "Net Banking",
  UPI: "UPI",
  upi: "UPI",
  wallet: "Wallet",
  credit_card_emi: "Credit Card EMI",
  debit_card_emi: "Debit Card EMI",
  cardless_emi: "Cardless EMI",
  pay_later: "Pay Later",
  upi_credit_card: "UPI Credit Card",
};
const gatewayName = {
  EDVIRON_PG: "Cashfree",
  EDVIRON_EASEBUZZ: "Easebuzz",
  EDVIRON_RAZORPAY: "Razorpay",
  PENDING: "Pending",
};
function Transaction() {
  const [transactionData, setTransactionData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const { setTransaction, user } = useContext(dashboardContext);
  const [transactionTotal, setTransactionAmount] = useState(0);
  const [orderAmountTotal, setOrderAmountTotal] = useState(0);
  const [dateFilter, setDateFilter] = useState<{
    type: string;
    startDate: string;
    endDate: string;
  }>({
    type: "",
    startDate: new Date(0).toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
    endDate: new Date().toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
  });

  const [selectedTime, setSelectedTime] = useState<string>("Date");
  const [selectStatus, setSelectStatus] = useState<any>({
    Success: false,
    Pending: false,
    Failed: false,
  });
  const [transactionReportData, settransactionReportData] = useState<any>([]);

  const { startDate, endDate } = getStartAndEndOfMonth();
  const [status, setStatus] = useState<any>(null);
  const [isCustomSearch, setIsCustomSearch] = useState(false);
  const [searchFilter, setSearchFilter] = useState<any>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isDateRangeIsSelected, setIsDateRangeIsSelected] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [refetchLoading, setRefetchLoading] = useState(false);
  const [selectSchool, setSelectSchool] = useState<any>("");
  const [schoolId, setSchoolId] = useState<any>(null);
  const [itemsPerRow, setItemsPerRow] = useState({ name: 10 });
  const [type, setType] = useState("");
  const [transactionAmountDetails, setTransactionAmountDetails] =
    useState<any>(null);
  const [filters, setFilters] = useState<any>({
    paymentMode: {
      credit_card: false,
      credit_card_emi: false,
      upi: false,
      wallet: false,
      pay_later: false,
      cardless_emi: false,
      net_banking: false,
      debit_card_emi: false,
      debit_card: false,
      na: false,
      qr: false,
    },
  });

  const {
    data: transactionReport,
    loading: transactionReportLoading,
    refetch,
  } = useQuery(GET_TRANSACTION_REPORT, {
    onCompleted: (data) => {
      const formattedData =
        data.getMerchantTransactionReport.transactionReport.map(
          (row: any, index: number) => {
            const gatewayKey = row.gateway?.toUpperCase();
            const gatewayname =
              gatewayKey && gatewayName[gatewayKey as keyof typeof gatewayName]
                ? gatewayName[gatewayKey as keyof typeof gatewayName] ===
                  "Pending"
                  ? "NA"
                  : gatewayName[gatewayKey as keyof typeof gatewayName]
                : "NA";
            return {
              transactionTime: row.createdAt,
              orderID: row.collect_id,
              details: row.details,
              transactionAmount:
                row.transaction_amount === null ? "--" : row.transaction_amount,
              paymentMode:
                row.payment_method === "" || row.payment_method === null
                  ? "NA"
                  : payment_method_map[row.payment_method],
              orderAmount: row.order_amount === null ? "--" : row.order_amount,
              transactionStatus:
                row.status === null ? "NA" : row.status.toLowerCase(),
              ...row,
              serialNumber: (currentPage - 1) * itemsPerRow.name + 1 + index,
              gateway: gatewayname,
            };
          }
        );
      setTransactionData(formattedData);
      settransactionReportData(formattedData);
    },
    variables: {
      startDate: startDate,
      endDate: endDate,
      page: currentPage.toString(),
      limit: itemsPerRow.name.toString(),
      //isQRCode: true,
    },
  });

  const refetchDataFetch = async ({
    start_date,
    end_date,
    page,
    status,
    school_id,
    isCustomSearch,
    searchFilter,
    searchParams,
    payment_modes,
    isQrCode,
  }: {
    start_date?: any;
    end_date?: any;
    page?: String;
    status?: String;
    school_id?: String;
    isCustomSearch?: boolean;
    searchFilter?: String;
    searchParams?: String;
    limit?: String;
    payment_modes?: string[] | null;
    isQrCode?: boolean;
  }) => {
    setRefetchLoading(true);

    try {
      const res = await refetch({
        startDate: start_date,
        endDate: end_date,
        page,
        status,
        school_id,
        isCustomSearch: isCustomSearch,
        searchFilter,
        searchParams,
        payment_modes: isQrCode ? null : payment_modes,
        isQRCode: isQrCode,
      });
      if (res) {
        setRefetchLoading(false);
        setTransactionData(
          res?.data.getMerchantTransactionReport.transactionReport.map(
            (row: any, index: number) => {
              const gatewayKey = row.gateway?.toUpperCase();
              const gatewayname =
                gatewayKey &&
                gatewayName[gatewayKey as keyof typeof gatewayName]
                  ? gatewayName[gatewayKey as keyof typeof gatewayName] ===
                    "Pending"
                    ? "NA"
                    : gatewayName[gatewayKey as keyof typeof gatewayName]
                  : "NA";
              return {
                transactionTime: row.createdAt,
                orderID: row.collect_id,
                details: row.details,
                transactionAmount:
                  row.transaction_amount === null
                    ? "--"
                    : row.transaction_amount,
                paymentMode:
                  row.payment_method === "" || row.payment_method === null
                    ? "NA"
                    : payment_method_map[row.payment_method],
                orderAmount:
                  row.order_amount === null ? "--" : row.order_amount,
                transactionStatus:
                  row.status === null ? "NA" : row.status.toLowerCase(),
                ...row,
                serialNumber: (currentPage - 1) * itemsPerRow.name + 1 + index,
                gateway: gatewayname,
              };
            }
          )
        );
      }
    } catch (error) {
      if (error) {
        setRefetchLoading(false);
      }
    }
  };

  const GET_TRANSACTION_AMOUNT = async (
    start_date: String,
    end_date: String,
    trustee_id: String,
    school_id: String,
    status: String,
    mode?: string[] | null,
    isQrCode?: boolean
  ) => {
    const token = localStorage.getItem("merchantToken");
    axios
      .post(
        `${process.env.REACT_APP_PAYMENT_BACKEND_URL}/edviron-pg/get-transaction-report-batched`,
        {
          trustee_id,
          school_id: school_id,
          start_date: start_date,
          end_date: end_date,
          status: status,
          mode: isQrCode ? null : mode,
          isQRPayment: isQrCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setTransactionAmountDetails(response.data.transactions[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GET_TRANSACTION_AMOUNT(
      isDateRangeIsSelected ? formatDate(selectedRange.startDate) : startDate,
      isDateRangeIsSelected ? formatDate(selectedRange.endDate) : endDate,
      user?.getMerchantQuery?.trustee_id,
      user?.getMerchantQuery?.school_id,
      status ? status?.toUpperCase() : "SUCCESS",
      getPaymentMode(filters.paymentMode, type),
      getPaymentMode(filters.paymentMode, type)?.includes("qr")
    );
  }, [
    type,
    status,
    selectSchool,
    searchFilter,
    itemsPerRow,
    selectedRange,
    filters,
  ]);

  useEffect(() => {
    const amount: any = [];
    const order_amount: any = [];
    transactionData.filter((d: any) => {
      if (d.transactionStatus === "success") {
        amount.push(d.transaction_amount);
        order_amount.push(d.order_amount);
      }
    });

    setOrderAmountTotal(
      order_amount?.reduce((a: any, current: any) => a + current, 0)
    );
    setTransactionAmount(
      amount?.reduce((a: any, current: any) => a + current, 0)
    );
  }, [transactionData]);

  useEffect(() => {
    refetchDataFetch({
      start_date: isDateRangeIsSelected
        ? formatDate(selectedRange.startDate)
        : startDate,
      end_date: isDateRangeIsSelected
        ? formatDate(selectedRange.endDate)
        : endDate,
      page: currentPage.toString(),
      status: status?.toUpperCase(),
      limit: itemsPerRow.toString(),
      school_id: selectSchool === "" ? null : schoolId,
      payment_modes:
        Object.keys(filters.paymentMode).filter(
          (key) => filters.paymentMode[key]
        ).length > 0
          ? Object.keys(filters.paymentMode).filter(
              (key) => filters.paymentMode[key]
            )
          : null,
      isQrCode: getPaymentMode(filters.paymentMode, type)?.includes("qr"),
    });
  }, [currentPage, itemsPerRow]);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (
      searchText.length > 3 &&
      ["order_id", "custom_order_id"].includes(searchFilter)
    ) {
      setIsCustomSearch(true);
    } else {
      setIsCustomSearch(false);
    }
  }, [searchText, searchFilter]);

  return (
    <div className="overflow-hidden">
      <h2 className="text-[#1B163B] text-[28px] ml-4 font-[600]">
        Transactions
      </h2>
      <div className="w-full  grid xl:grid-cols-2 gap-4 mb-2">
        <div className="xl:col-span-1 col-span-2">
          <h2 className="text-[#1B163B] xl:text-[24px] text-lg ml-2  font-[400]">
            Transactions Amount
          </h2>

          <div className="text-[#229635] font-[400] flex items-center ">
            {/* {transactionReportLoading || refetchLoading ? (
              <img
                src={PriceLoading}
                className=" w-10 h-10 animate-spin"
                alt="loading"
              />
            ) : (
              <>
                
              </>
            )} */}

            <span className="xl:text-[44px] text-3xl flex items-center">
              <LiaRupeeSignSolid />{" "}
              {transactionAmountDetails !== null &&
              (status === "success" || status === null) ? (
                <span>
                  {transactionAmountDetails?.totalTransactionAmount.toLocaleString(
                    "hi-in"
                  )}
                </span>
              ) : (
                <span>0</span>
              )}
            </span>
            <span className="text-[20px] text-[#717171] flex items-center ml-2">
              {` (selected period )`}
            </span>
          </div>
        </div>

        <div className="xl:col-span-1 col-span-2">
          <h2 className="text-[#1B163B] xl:text-[24px] text-lg ml-2  font-[400]">
            Order Amount
          </h2>
          <div className="text-[#229635] font-[400] flex items-center ">
            <span className="xl:text-[44px] text-3xl flex items-center">
              <LiaRupeeSignSolid />
              {transactionAmountDetails !== null &&
              (status === "success" || status === null) ? (
                <span>
                  {transactionAmountDetails?.totalOrderAmount.toLocaleString(
                    "hi-in"
                  )}
                </span>
              ) : (
                <span>0</span>
              )}
            </span>
            <span className="text-[20px] text-[#717171] flex items-center ml-2">
              {` (selected period )`}
            </span>
          </div>
        </div>
      </div>
      {transactionReportData ? (
        <_Table
          perPage={false}
          exportBtn={true}
          heading={"History"}
          pagination={false}
          copyContent={[3]}
          filter={[searchText]}
          loading={refetchLoading || transactionReportLoading}
          searchBox={
            <div className="w-full ">
              <div className="flex xl:!flex-row flex-col justify-between gap-2  w-full xl:items-center items-start mb-2">
                <div className="bg-[#EEF1F6] py-3 items-center flex  px-3 xl:max-w-md max-w-[34rem] w-full rounded-lg">
                  <input
                    className="text-xs pr-2 bg-transparent focus:outline-none w-full placeholder:font-normal"
                    type="text"
                    value={searchText}
                    placeholder=" Search(Order ID...)"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  {searchFilter !== "" && searchText.length > 3 && (
                    <HiMiniXMark
                      onClick={async () => {
                        setSearchFilter("");
                        setSearchText("");
                        refetchDataFetch({
                          start_date: startDate,
                          end_date: endDate,
                        });
                      }}
                      className="text-[#1E1B59] cursor-pointer text-md mr-2 shrink-0"
                    />
                  )}
                  <Select
                    className="border-l-2 border-gray-400"
                    options={[
                      {
                        label: "By Order ID",
                        value: "custom_order_id",
                      },
                      { label: "By Edviron Order ID", value: "order_id" },
                      { label: "By Student Info", value: "student_info" },
                      { label: "By UPI Transaction ID", value: "bank_reference" },
                      { label: "By UPI Id", value: "upi_id" }
                    ]}
                    isSearchable={false}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e: any) => {
                      setSearchFilter(e.value.toLowerCase());
                      setCurrentPage(1);
                    }}
                    placeholder={
                      <div className="text-[#1E1B59] -mt-1 capitalize text-[9px]">
                        {searchFilter === ""
                          ? "filter by"
                          : searchFilter
                              .toString()
                              .toLowerCase()
                              .replaceAll("_", " ") === "order id"
                          ? "Edviron Order ID"
                          : searchFilter
                              .toString()
                              .toLowerCase()
                              .replaceAll("_", " ")}
                      </div>
                    }
                    value={searchFilter}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        backgroundColor: "transparent",
                        height: "20px",
                        border: "none",
                        boxShadow: "none",
                        cursor: "pointer",
                        minHeight: "10px",
                        padding: "0px",
                      }),
                      valueContainer: (provided: any) => ({
                        ...provided,
                        height: "20px",
                        width: "8rem",
                        padding: "0 8px",
                      }),
                      input: (provided: any) => ({
                        ...provided,
                        margin: "0",
                        padding: "0",
                      }),
                      placeholder: (provided: any) => ({
                        ...provided,
                        margin: "0",
                        padding: "0",
                        lineHeight: "20px",
                      }),
                      singleValue: (provided: any) => ({
                        ...provided,
                        lineHeight: "20px",
                      }),
                      indicatorsContainer: (provided: any) => ({
                        ...provided,
                        height: "20px",
                      }),
                      option: (provided: any) => ({
                        ...provided,
                        fontSize: "10px",
                        cursor: "pointer",
                      }),
                    }}
                  />

                  <div className="w-10 z-50 shrink-0 flex justify-center items-center">
                    {searchText.length > 3 && refetchLoading ? (
                      <AiOutlineLoading3Quarters className="text-xs animate-spin" />
                    ) : (
                      <i
                        onClick={() => {
                          if (searchText.length > 3 && searchFilter !== "") {
                            setCurrentPage(1);
                            refetchDataFetch({
                              isCustomSearch,
                              searchFilter: searchFilter,
                              searchParams: searchText,
                              start_date: isDateRangeIsSelected
                                ? formatDate(selectedRange.startDate)
                                : startDate,
                              end_date: isDateRangeIsSelected
                                ? formatDate(selectedRange.endDate)
                                : endDate,
                              status: status?.toUpperCase(),
                              school_id: schoolId === "" ? null : schoolId,
                              payment_modes: getPaymentMode(
                                filters.paymentMode,
                                type,
                              ),
                              isQrCode: getPaymentMode(
                                filters.paymentMode,
                                type,
                              )?.includes("qr"),
                              // gateway: getPaymentMode(filters.gateway, type),
                            });
                          }
                        }}
                        className=" cursor-pointer text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"
                      ></i>
                    )}
                  </div>
                </div>

                <div className="flex justify-end items-center flex-1 w-full max-w-[34rem]">
                  <TransactionDateFilter
                    setType={setDateRange}
                    type={dateRange}
                    refetch={() => {
                      refetchDataFetch({
                        start_date: formatDate(selectedRange.startDate),
                        end_date: formatDate(selectedRange.endDate),
                        status: status?.toUpperCase(),
                        school_id: schoolId === "" ? null : schoolId,
                        isQrCode: getPaymentMode(
                          filters.paymentMode,
                          type
                        )?.includes("qr"),
                      });
                    }}
                    selectedRange={selectedRange}
                    setSelectedRange={setSelectedRange}
                    setIsDateRangeIsSelected={setIsDateRangeIsSelected}
                  />
                  <div className="w-full">
                    <Select
                      className="font-normal m-0 p-2 capitalize"
                      options={[
                        { label: "SUCCESS", value: "SUCCESS" },
                        { label: "PENDING", value: "PENDING" },
                        { label: "FAILED", value: "FAILED" },
                      ].map((status: any) => {
                        return {
                          label: status.label?.toLowerCase(),
                          value: status.value?.toLowerCase(),
                        };
                      })}
                      components={{
                        DropdownIndicator: CustomDropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e: any) => {
                        setStatus(e.value);
                        setCurrentPage(1);
                        refetchDataFetch({
                          start_date: isDateRangeIsSelected
                            ? formatDate(selectedRange.startDate)
                            : startDate,
                          end_date: isDateRangeIsSelected
                            ? formatDate(selectedRange.endDate)
                            : endDate,
                          status: e.value?.toUpperCase(),
                          isCustomSearch: isCustomSearch,
                          searchFilter: searchFilter,
                          searchParams: searchText,
                          school_id: selectSchool === "" ? null : schoolId,
                          payment_modes: getPaymentMode(
                            filters.paymentMode,
                            type
                          ),
                          isQrCode: getPaymentMode(
                            filters.paymentMode,
                            type
                          )?.includes("qr"),
                        });
                      }}
                      placeholder={
                        <div className="text-[#1E1B59] text-xs">Status</div>
                      }
                      value={null}
                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          backgroundColor: "#F6F8FA",
                          border: "1px solid #1B163B",
                          borderRadius: "6px",

                          minHeight: "15px",
                          margin: "0px",
                          color: "#6687FF",
                        }),
                        valueContainer: (provided: any) => ({
                          ...provided,
                          padding: "0px",
                          paddingLeft: "0.5rem",
                        }),
                        input: (provided: any) => ({
                          ...provided,
                          backgroundColor: "transparent",
                          color: "#000",
                          "::placeholder": {
                            backgroundColor: "#YourDesiredColor",
                            opacity: 1,
                          },
                          placeholder: (provided: any) => ({
                            ...provided,
                            color: "red", // Set the color of the placeholder text
                          }),
                        }),
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <MixFilter
                      setSelectSchool={setSelectSchool}
                      setSchoolId={setSchoolId}
                      paymentModes={Object.keys(filters.paymentMode).filter(
                        (key) => filters.paymentMode[key]
                      )}
                      setType={setType}
                      onCancel={() => {
                        refetchDataFetch({
                          start_date: startDate,
                          end_date: endDate,
                        });
                      }}
                      onApply={() => {
                        refetchDataFetch({
                          start_date: isDateRangeIsSelected
                            ? formatDate(selectedRange.startDate)
                            : startDate,
                          end_date: isDateRangeIsSelected
                            ? formatDate(selectedRange.endDate)
                            : endDate,
                          status: status?.toUpperCase(),
                          limit: itemsPerRow.toString(),
                          school_id: selectSchool === "" ? null : schoolId,
                          payment_modes: getPaymentMode(
                            filters.paymentMode,
                            type
                          )?.includes("qr")
                            ? null
                            : getPaymentMode(filters.paymentMode, type),
                          isQrCode: getPaymentMode(
                            filters.paymentMode,
                            type
                          )?.includes("qr"),
                        });
                      }}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </div>
                </div>
              </div>
              <div>
                <RowsPerPageSelect
                  setItemsPerRow={(e: any) => {
                    setCurrentPage(1);
                    setItemsPerRow(e);
                  }}
                  itemsPerRow={itemsPerRow}
                  className=" justify-start"
                />
              </div>
              <div className="flex items-center">
                {type !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setSelectedRange({
                          startDate: startOfDay(new Date()),
                          endDate: endOfDay(new Date()),
                          key: "selection",
                        });
                        if (status || schoolId) {
                          setType("");
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                            status: status?.toUpperCase(),
                            school_id: schoolId === "" ? null : schoolId,
                          });

                          setFilters({
                            paymentMode: {
                              credit_card: false,
                              credit_card_emi: false,
                              upi: false,
                              wallet: false,
                              pay_later: false,
                              cardless_emi: false,
                              net_banking: false,
                              debit_card_emi: false,
                              debit_card: false,
                              na: false,
                            },
                          });

                          setIsDateRangeIsSelected(false);
                        } else {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                          setType("");
                          setFilters({
                            paymentMode: {
                              credit_card: false,
                              credit_card_emi: false,
                              upi: false,
                              wallet: false,
                              pay_later: false,
                              cardless_emi: false,
                              net_banking: false,
                              debit_card_emi: false,
                              debit_card: false,
                              na: false,
                            },
                          });
                          setIsDateRangeIsSelected(false);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {type} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )}
                {dateRange !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setSelectedRange({
                          startDate: startOfDay(new Date()),
                          endDate: endOfDay(new Date()),
                          key: "selection",
                        });
                        if (status || schoolId) {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                            status: status?.toUpperCase(),
                            school_id: schoolId === "" ? null : schoolId,
                            payment_modes: getPaymentMode(
                              filters.paymentMode,
                              type
                            ),
                            isQrCode: getPaymentMode(
                              filters.paymentMode,
                              type
                            )?.includes("qr"),
                          });
                          setDateRange("");
                          setIsDateRangeIsSelected(false);
                        } else {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                          setDateRange("");
                          setIsDateRangeIsSelected(false);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {dateRange} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )}
                {selectSchool !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={() => {
                        if (status || isDateRangeIsSelected || type) {
                          refetchDataFetch({
                            start_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            end_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            status: status?.toUpperCase(),

                            payment_modes: getPaymentMode(
                              filters.paymentMode,
                              type
                            ),
                            isQrCode: getPaymentMode(
                              filters.paymentMode,
                              type
                            )?.includes("qr"),
                          });
                          setSelectSchool("");
                          setSchoolId(null);
                        } else {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                          setSelectSchool("");
                          setSchoolId(null);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {selectSchool} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )}
                {status && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setCurrentPage(1);
                        if (selectSchool || isDateRangeIsSelected) {
                          refetchDataFetch({
                            start_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            end_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            school_id: schoolId === "" ? null : schoolId,
                            payment_modes: getPaymentMode(
                              filters.paymentMode,
                              type
                            ),
                            isQrCode: getPaymentMode(
                              filters.paymentMode,
                              type
                            )?.includes("qr"),
                          });
                          setStatus(null);
                        } else {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                            payment_modes: getPaymentMode(
                              filters.paymentMode,
                              type
                            ),
                            isQrCode: getPaymentMode(
                              filters.paymentMode,
                              type
                            )?.includes("qr"),
                          });
                          setStatus(null);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {status} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
          data={[
            [
              "Sr.No",
              "Date & Time",
              "Order ID",
              "Order Amt",
              "Transaction Amt",
              "Payment Method",
              "Status",
              "Student Name",
              "Phone No.",
              "Gateway",
              "Capture Status",
            ],
            ...transactionData.map((row: any, index: number) => [
              <div>{row.serialNumber}</div>,
              <Link
                state={{ collect_id: row?.orderID }}
                to="/payments/transaction-receipt"
              >
                <div className="truncate" key={row.orderID}>
                  {new Date(row?.transactionTime).toLocaleString("hi")}
                </div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID }}
                to="/payments/transaction-receipt"
              >
                <div className=" truncate" key={row.orderID}>
                  {row.custom_order_id ? row.custom_order_id : row.orderID}
                </div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID }}
                to="/payments/transaction-receipt"
              >
                <div key={row.orderID}>{`₹${row.orderAmount}`}</div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID }}
                to="/payments/transaction-receipt"
              >
                <div key={row.orderID}>{`₹${row.transactionAmount}`}</div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID }}
                to="/payments/transaction-receipt"
              >
                <div key={row.orderID}>{row.paymentMode}</div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID }}
                to="/payments/transaction-receipt"
              >
                <div
                  className={`capitalize ${
                    row.transactionStatus === "success"
                      ? "text-[#04B521]"
                      : row.transactionStatus === "failure" ||
                        row.transactionStatus === "failed"
                      ? "text-[#E54F2F]"
                      : row.transactionStatus === "pending"
                      ? "text-yellow-400"
                      : ""
                  }`}
                  key={row.orderID}
                >
                  {row.transactionStatus?.replaceAll("_", " ")}
                </div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID }}
                to="/payments/transaction-receipt"
              >
                <div key={row.orderID}>
                  {row.student_name ? row.student_name : "NA"}
                </div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID }}
                to="/payments/transaction-receipt"
              >
                <div key={row.orderID}>
                  {row.student_phone ? row.student_phone : "NA"}
                </div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID, schoolId: row?.schoolId }}
                to="/payments/transaction-receipt"
              >
                <div className="truncate" key={row.orderID}>
                  {row?.gateway}
                </div>
              </Link>,
              <Link
                state={{ collect_id: row?.orderID, schoolId: row?.schoolId }}
                to="/payments/transaction-receipt"
              >
                <div className="truncate " key={row.orderID}>
                  {row?.capture_status || "NA"}
                </div>
              </Link>,
            ]),
          ]}
          footer={
            <div className="flex justify-center items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  transactionReport?.getMerchantTransactionReport?.total_pages
                )}
                onPageChange={handlePageChange}
              />
            </div>
          }
        />
      ) : null}
    </div>
  );
}
export default Transaction;
