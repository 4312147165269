/* eslint-disable react/jsx-pascal-case */
import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import { _Table } from "../../../components/Table";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { amountFormat } from "../../../utils/amountFormat";

import Select from "react-select";
import DateFilter from "./DateFilter";
import { HiMiniXMark } from "react-icons/hi2";
import { CustomDropdownIndicator } from "../Settlement/Settlement";
import { GET_REFUND_REQUESTS } from "../../../Qurries";
import { endOfDay, startOfDay } from "date-fns";
import { Link } from "react-router-dom";
function Refund() {
  const { data: refundData, loading } = useQuery(GET_REFUND_REQUESTS);
  const [searchText, setSearchText] = useState<string>("");

  const [status, setStatus] = useState("");

  const [type, setType] = useState("");
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [refundRequest, setRefundRequest] = useState<any>([]);
  const [refundRequestData, setRefundRequestData] = useState<any>([]);

  useEffect(() => {
    if (refundData?.getRefundRequests) {
      setRefundRequestData(refundData?.getRefundRequests);
    }
  }, [refundData?.getRefundRequests]);
  useEffect(() => { }, [refundRequestData]);

  return (
    <div className="w-full pt-2">
      {loading ? (
        <div className="min-h-[80vh] w-full flex justify-center items-center">
          <AiOutlineLoading3Quarters className="text-3xl animate-spin" />
        </div>
      ) : (
        <>
          {refundData?.getRefundRequests ? (
            <_Table
              pagination={true}
              heading={"Refund"}
              searchBox={
                <div className="w-full flex flex-col">
                  <div className="flex w-full items-center mb-2">
                    <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 max-w-md w-full rounded-lg">
                      <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                      <input
                        className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                        onFocus={(e) =>
                          (e.target.style.borderColor = "transparent")
                        }
                        type="text"
                        placeholder=" Search(Institute Name,Order ID...)"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </div>

                    <div className="flex justify-end items-center flex-1">
                      <DateFilter
                        originalData={refundData?.getRefundRequests}
                        setRefundRequest={setRefundRequestData}
                        setType={setType}
                        type={type}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                      />
                      <Select
                        className="font-normal m-0 p-2 capitalize"
                        options={[
                          { label: "INITIATED", value: "INITIATED" },
                          { label: "REJECTED", value: "REJECTED" },
                          { label: "APPROVED", value: "APPROVED" },
                          {
                            label: "DELETED BY USER",
                            value: "DELETED BY USER",
                          },
                        ].map((status: any) => {
                          return {
                            label: status.label.toLowerCase(),
                            value: status.value,
                          };
                        })}
                        components={{
                          DropdownIndicator: CustomDropdownIndicator,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e: any) => {
                          setStatus(e.value);
                          //setType(e.value);
                        }}
                        placeholder={
                          <div className="text-[#1E1B59] text-xs">Status</div>
                        }
                        value={null}
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            backgroundColor: "#F6F8FA",
                            border: "1px solid #1B163B",
                            borderRadius: "6px",
                            width: "180px",
                            minHeight: "15px",
                            margin: "0px",
                            color: "#6687FF",
                          }),
                          valueContainer: (provided: any) => ({
                            ...provided,
                            padding: "0px",
                            paddingLeft: "0.5rem",
                          }),
                          input: (provided: any) => ({
                            ...provided,
                            backgroundColor: "transparent",
                            color: "#000",
                            "::placeholder": {
                              backgroundColor: "#YourDesiredColor",
                              opacity: 1,
                            },
                            placeholder: (provided: any) => ({
                              ...provided,
                              color: "red", // Set the color of the placeholder text
                            }),
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    {type !== "" && (
                      <div className=" text-sm m-2  max-w-fit ">
                        <button
                          onClick={() => {
                            if (!status) {
                              setRefundRequestData(
                                refundData?.getRefundRequests
                              );
                              setType("");
                            } else {
                              setRefundRequestData(
                                refundData?.getRefundRequests
                              );
                              setSelectedRange({
                                startDate: startOfDay(new Date()),
                                endDate: endOfDay(new Date()),
                                key: "selection",
                              });
                              setType("");
                            }
                          }}
                          className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                        >
                          {type} <HiMiniXMark className=" text-lg ml-1" />
                        </button>
                      </div>
                    )}
                    {status !== "" && (
                      <div className=" text-sm m-2  max-w-fit ">
                        <button
                          onClick={() => {
                            if (!type) {
                              setRefundRequestData(
                                refundData?.getRefundRequests
                              );
                              setStatus("");
                            } else {
                              setStatus("");
                            }
                          }}
                          className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                        >
                          {status} <HiMiniXMark className=" text-lg ml-1" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              }
              filter={[searchText]}
              copyContent={[2, 3]}
              data={[
                [
                  "Sr.No",

                  "Refund ID",
                  "Order ID",
                  "Refund Amount",
                  "Order Amount",
                  "Transaction Amount",
                  "Status",
                  "Created Date",
                  "Updated Date",
                  "Reason",
                ],
                ...refundRequestData
                  ?.filter((d: any) => {
                    const arr = [d._id, d.school_id, d.order_id].join(",");

                    return (
                      (!status || d.status === status) &&
                      (!searchText ||
                        arr.toLowerCase().includes(searchText.toLowerCase()))
                    );
                  })
                  .map((data: any, index: any) => [
                    <div>{index + 1}</div>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate max-w-[10rem]">
                        {data._id}
                      </div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate">{data.order_id}</div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate">
                        {amountFormat(data.refund_amount)}
                      </div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >

                      <div className="truncate">
                        {amountFormat(data.order_amount)}
                      </div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate">
                        {amountFormat(data.transaction_amount)}
                      </div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate">
                        {data.status !== "APPROVED"
                          ? data.status
                          : "PROCESSED/APPROVED"}
                      </div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate">
                        {new Date(Number(data?.createdAt)).toLocaleString("hi")}
                      </div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate">
                        {new Date(Number(data?.updatedAt)).toLocaleString("hi")}
                      </div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate">{data?.reason || "NA"}</div>
                    </Link>,
                  ]),
              ]}
            />
          ) : (
            <>{null}</>
          )}
        </>
      )}
    </div>
  );
}

export default Refund;
