/* eslint-disable react/jsx-pascal-case */
import { useMutation, useQuery } from "@apollo/client";
import {
  Form,
  preventNegativeValues,
  preventPasteNegative,
  Select,
} from "edviron-ui-package";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters, AiTwotoneDelete } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ADD_REMARK,
  DELETE_REFUND,
  DELETE_REMARK,
  GET_MERCHANT_SINGLE_TRANSACTION_INFO,
  GET_REFUND_STATUS,
  GET_TRANSACTION_REPORT,
  INITIATE_REFUND,
  INITIATE_SPLIT_REFUND,
} from "../../../Qurries";
import Aword from "../../../assets/loading (2).svg";
import refundIcon from "../../../assets/refund.svg";
import ConfirmationBox from "../../../components/ConfermationBox";
import Input from "../../../components/Input/Input";
import Modal from "../../../components/Modal/Modal";
import { _Table } from "../../../components/Table";
import { amountFormat } from "../../../utils/amountFormat";
import { getVendorAmount } from "../../../utils/getVendorAmount";
import { extractRemainingPart } from "../../../utils/helper";
import { payment_method_map } from "./Transaction";
import { getBankName } from "../../../utils/getBankName";

function PaymentSmallCard({ title, value, icon }: any) {
  return (
    <div className=" flex text-xs font-normal space-y-2 flex-col">
      <div className="flex justify-between items-center max-w-[10rem]">
        <h4 className="  text-[#767676]">{title}</h4>
        {icon && icon}
      </div>
      <h3 className=" text-[#1B163B] font-semibold capitalize">
        {value && value ? value : "NA"}
      </h3>
    </div>
  );
}

function TransactionReceipt() {
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [openDeleteRemarkModal, setOpenDeleteRemarkModal] = useState(false);
  const [openInitiateRefundModal, setOpenInitiateRefundModal] = useState(false);
  const [confirmInitiateRefundModal, setConfimInitiateRefundModal] =
    useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [refunAmount, setRefundAmount] = useState(0);
  const [remark, setRemark] = useState("");
  const [deleteRefundRequest, setDeleteRefundRequest] = useState(false);
  const [refundId, setRefundId] = useState("");
  const [transactionInfo, setTransactionInfo] = useState<any>(null);
  const [remarkEdit, setRemarkEdit] = useState(false);
  const [finalAmount, setFinalAmount] = useState<any>(0);
  const [refundReason, setRefundReason] = useState("");
  const [concernPerson, setConcernPerson] = useState({
    name: "Merchant",
  });

  const { state } = useLocation();

  const { data: getTransactionInfo, loading: singleTransactionInfoLoading } =
    useQuery(GET_MERCHANT_SINGLE_TRANSACTION_INFO, {
      variables: {
        collect_id: state?.collect_id,
      },
      onCompleted(data) {
        const formattedData = data?.getMerchantSingleTransactionReport?.map(
          (item: any) => ({
            paymentMode:
              item.payment_method === "" || item.payment_method === null
                ? "NA"
                : payment_method_map[item.payment_method],
            ...item,
          })
        );

        setTransactionInfo(formattedData[0]);
      },
      skip: !state,
    });

  const concern_person_options =
    transactionInfo?.vendors_info?.length > 0
      ? ["Merchant", ...transactionInfo?.vendors_info.map((d: any) => d?.name)]
      : ["Merchant"];

  const vendor_refund_amount = getVendorAmount({
    array: transactionInfo?.vendors_info,
    orderAmount: transactionInfo?.order_amount,
  });

  const {
    data,
    loading: statusLoading,
    refetch,
  } = useQuery(GET_REFUND_STATUS, {
    variables: {
      order_id: state?.collect_id,
    },
    skip: !state,
  });

  useEffect(() => {
    refetch();
  }, []);

  const refundAmountTotal = data?.getRefundRequestMerchant
    .map((d: any) => {
      if (
        (d.status === "APPROVED" ||
          d.status === "INITIATED" ||
          d.status === "PROCESSING") &&
        d.split_refund_details.length <= 0
      ) {
        return d.refund_amount;
      }
    })
    .filter((d: any) => d !== undefined)
    .reduce((acc: any, current: any) => acc + current, 0);

  // const { data: utrData } = useQuery(GET_TRANSACTION_UTR, {
  //   variables: {
  //     date: transaction?.transactionTime,
  //     school_id: transaction?.school_id,
  //   },
  // });

  useEffect(() => {
    let vendor_amount;
    const vendorId = transactionInfo?.vendors_info.find(
      (d: any) => d.name === concernPerson.name
    )?.vendor_id;

    if (!vendorId) {
      console.error("Vendor ID not found");
    } else {
      vendor_amount = data?.getRefundRequestMerchant.reduce(
        (acc: number, merchant: any) => {
          if (!merchant.split_refund_details.length) return acc;

          const matchedDetail = merchant.split_refund_details.find(
            (detail: any) => detail.vendor_id.includes(vendorId)
          );

          return matchedDetail ? matchedDetail.amount : acc;
        },
        0
      );
    }

    let finalAmount;
    if (concernPerson.name === "Merchant") {
      finalAmount =
        refundAmountTotal >= 0 &&
        transactionInfo?.order_amount -
        refundAmountTotal -
        vendor_refund_amount;
    } else {
      finalAmount =
        getVendorAmount({
          array: [
            transactionInfo?.vendors_info.find(
              (d: any) => d.name === concernPerson.name
            ),
          ],
          orderAmount: transactionInfo?.order_amount,
        }) - vendor_amount;
    }

    setFinalAmount(finalAmount);
  }, [concernPerson]);

  const [addRemark] = useMutation(ADD_REMARK, {
    refetchQueries: [{ query: GET_TRANSACTION_REPORT }],
  });
  const [deleteRemark] = useMutation(DELETE_REMARK, {
    refetchQueries: [{ query: GET_TRANSACTION_REPORT }],
  });
  const [initiateRefund, { loading }] = useMutation(INITIATE_REFUND);
  const [vendorRefundInitiate, { loading: vendorRefundLoading }] = useMutation(
    INITIATE_SPLIT_REFUND
  );
  const [deleteRefund] = useMutation(DELETE_REFUND);

  const handlePrint = (elem: any) => {
    const printContents: any = document.getElementById("receipt");
    html2canvas(printContents).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "pt", "a4");
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("download.pdf");
    });
  };

  useEffect(() => {
    if (openInitiateRefundModal) {
      setConfimInitiateRefundModal(false);
      setConcernPerson({ name: "Merchant" });
    }
  }, [openInitiateRefundModal]);

  if (state === null) {
    return <Navigate to="/payments"></Navigate>;
  }

  if (singleTransactionInfoLoading) {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-[80vh] rounded-lg mt-4 bg-[#F6F8FA] p-4">
      <Modal
        title="Add Remark"
        open={openRemarkModal}
        setOpen={setOpenRemarkModal}
      >
        <div>
          <textarea
            name="remark"
            id="remark"
            onChange={(e) => {
              setRemark(e.target.value);
            }}
            className="w-full border rounded-md h-24 focus:outline-none p-4"
          ></textarea>
          <button
            disabled={!remark}
            onClick={async () => {
              const res = await addRemark({
                variables: {
                  collect_id: transactionInfo?.collect_id,
                  remark: remark,
                },
              });
              if (res.data.addMerchantRemarks) {
                toast.success(res.data.addMerchantRemarks);
                window.location.reload();
                setOpenRemarkModal(false);
              }
            }}
            className="px-4 py-1.5 rounded-lg disabled:bg-gray-200 disabled:text-edviron_black disabled:cursor-not-allowed bg-[#6687FF] text-white"
          >
            Add Remark
          </button>
        </div>
      </Modal>
      <Modal open={deleteRefundRequest} setOpen={setDeleteRefundRequest}>
        <ConfirmationBox
          setOpen={setDeleteRefundRequest}
          funtion={async () => {
            const res = await deleteRefund({
              variables: {
                refund_id: refundId,
              },
            });

            if (res?.data.deleteRefundRequest) {
              toast.success("Request deleted successfully");
              refetch();
            }
          }}
          confirmationText="Delete Refund Request"
          ButtonText="Delete"
        />
      </Modal>
      <Modal title="Edit Remark" open={remarkEdit} setOpen={setRemarkEdit}>
        <div>
          <textarea
            name="remark"
            defaultValue={transactionInfo?.remarks}
            onChange={(e) => {
              setRemark(e.target.value);
            }}
            className="w-full border rounded-md h-24 focus:outline-none p-4"
          ></textarea>
          <button
            disabled={!remark}
            onClick={async () => {
              const res = await addRemark({
                variables: {
                  collect_id: transactionInfo?.collect_id,
                  remark: remark,
                },
              });
              if (res.data.addMerchantRemarks) {
                toast.success(res.data.addMerchantRemarks);
                window.location.reload();
                setRemarkEdit(false);
              }
            }}
            className="px-4 py-1.5 rounded-lg disabled:bg-gray-200 disabled:text-edviron_black disabled:cursor-not-allowed bg-[#6687FF] text-white"
          >
            Edit Remark
          </button>
        </div>
      </Modal>
      <Modal open={openDeleteRemarkModal} setOpen={setOpenDeleteRemarkModal}>
        <ConfirmationBox
          setOpen={setOpenDeleteRemarkModal}
          funtion={async () => {
            const res = await deleteRemark({
              variables: {
                collect_id: transactionInfo?.collect_id,
              },
            });
            if (res.data.deleteMerchantRemark) {
              toast.success(res.data.deleteMerchantRemark);
              window.location.reload();
            }
          }}
          confirmationText="Delete Remark"
          ButtonText="Delete Remark"
        />
      </Modal>
      <Modal
        open={openInitiateRefundModal}
        setOpen={setOpenInitiateRefundModal}
      >
        <Form
          onSubmit={async (data: any) => {
            setRefundAmount(0);
            try {
              if (concernPerson.name === "Merchant") {
                const res = await initiateRefund({
                  variables: {
                    order_id: transactionInfo.collect_id,
                    refund_amount: Number(refunAmount),
                    order_amount: transactionInfo.order_amount,
                    transaction_amount: transactionInfo.transaction_amount,
                    reason: refundReason.trim() !== "" ? refundReason : "NA",
                  },
                });

                setOpenInitiateRefundModal(false);
                setRefundReason("");
                if (res?.data) {
                  refetch();
                  toast.success(res.data?.initiateRefundRequest);
                }
              } else {
                const res = await vendorRefundInitiate({
                  variables: {
                    order_id: transactionInfo.collect_id,
                    refund_amount: Number(refunAmount),
                    order_amount: transactionInfo.order_amount,
                    transaction_amount: transactionInfo.transaction_amount,
                    reason: refundReason.trim() !== "" ? refundReason : "NA",
                    split_refund_details: {
                      vendor_id: transactionInfo.vendors_info.find(
                        (d: any) => d.name === concernPerson.name
                      ).vendor_id,
                      amount: Number(refunAmount),
                    },
                  },
                });

                setOpenInitiateRefundModal(false);
                setRefundReason("");
                if (res?.data) {
                  refetch();

                  toast.success(res.data?.initiateSplitRefund);
                }
              }
            } catch (error) { }
          }}
        >
          <Select
            selected={concernPerson}
            setSelected={setConcernPerson}
            label="Select Account"
            options={concern_person_options.map((d) => ({ name: d }))}
          />
          <Input
            type="text"
            name="Order Amount"
            labelStyle="text-sm font-medium text-start text-gray-900"
            value={transactionInfo?.order_amount}
            add_error={() => { }}
            required
            disabled
          />
          <Input
            type="text"
            name="Transaction Amount"
            labelStyle="text-sm font-medium text-start text-gray-900"
            value={transactionInfo?.transaction_amount}
            add_error={() => { }}
            required
            disabled
          />

          {/* {confirmInitiateRefundModal === false && (
          )} */}

          <Input
            type="number"
            name="Refund Amount"
            labelStyle="text-sm font-medium text-start text-gray-900"
            step="any"
            add_error={() => { }}
            onChange={(d: number) => {
              setRefundAmount(d);
            }}
            placeholder="Enter Refund Amount"
            onKeyDown={preventNegativeValues}
            onPaste={preventPasteNegative}
            min={0}
            maxAmount={finalAmount}
            required
          />
          <p className="text-gray-500 sm:text-sm text-right ">
            Enter amount upto Rs.
            {finalAmount}
          </p>

          <div>
            <p className="text-sm font-medium mb-2 text-start text-gray-900">
              Refund Reason
            </p>
            <textarea
              name="remark"
              id="remark"
              placeholder="Enter Refund Reason"
              onChange={(e) => {
                setRefundReason(e.target.value);
              }}
              className="w-full border rounded-md h-20 focus:outline-none py-2 px-4 resize-none"
            ></textarea>
          </div>

          {confirmInitiateRefundModal ? (
            <div className=" space-y-3 mt-2">
              <div>
                <p className="font-bold  text-sm">
                  To confirm, type{" "}
                  <span className="capitalize">
                    {`"${concernPerson?.name.replace(
                      /\s+/g,
                      ""
                    )}${refunAmount}"`}{" "}
                  </span>
                  in the box below
                </p>
                <input
                  type="text"
                  name="confirmation-text"
                  onChange={(e) => {
                    setConfirmationText(e.target.value);
                  }}
                  className="py-2 text-sm mt-2 px-4 w-full rounded-lg border focus:outline-none"
                />
              </div>
              <button
                //type="submit"
                disabled={
                  confirmationText !==
                  concernPerson?.name.replace(/\s+/g, "") + refunAmount
                }
                className="py-2 text-sm px-16 w-full  border rounded-lg disabled:bg-gray-300 disabled:text-gray-800 bg-green-500 text-white  disabled:opacity-50"
              >
                Initiate Refund
              </button>
            </div>
          ) : (
            <div className="mt-2 mb-2 text-center">
              <button
                type="button"
                disabled={!(refunAmount > 0)}
                onClick={() => {
                  setConfimInitiateRefundModal(true);
                }}
                className="py-2 px-16 max-w-[15rem] w-full rounded-lg disabled:bg-blue-300 bg-[#6F6AF8] text-white "
              >
                Initiate
              </button>
            </div>
          )}
        </Form>
      </Modal>

      <div className="flex justify-between items-center">
        <h3 className="flex items-center text-lg font-semibold text-[#1B163B]">
          <Link to="/payments">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className=" h-6 mr-2 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </Link>
          Transaction Receipt
        </h3>
        <div className="flex gap-x-4 items-center">
          {!transactionInfo?.remarks ? (
            <button
              className="px-4 py-1.5 text-sm  rounded-md border-2 text-[#1B163B] border-[#6687FF] "
              onClick={() => setOpenRemarkModal(true)}
            >
              Add Remark
            </button>
          ) : (
            <button
              disabled={!transactionInfo?.remarks}
              onClick={() => {
                setOpenDeleteRemarkModal(true);
              }}
              className="px-4 py-1.5 text-sm  rounded-md border-2 text-[#1B163B] border-[#6687FF] "
            >
              Remark Delete
            </button>
          )}
          <button
            className="px-4 py-1.5 flex text-sm items-center gap-x-2 rounded-md bg-[#6687FF] text-white"
            onClick={() => handlePrint("receipt")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
              />
            </svg>
            Print
          </button>
          {transactionInfo?.status?.toLowerCase() === "success" &&
            refundAmountTotal !== transactionInfo?.order_amount && (
              <button
                className="px-4 py-1.5 flex text-sm items-center gap-x-2 rounded-md bg-[#6687FF] text-white"
                onClick={() => {
                  setOpenInitiateRefundModal(true);
                }}
              >
                <img src={refundIcon} alt="" className="w-6 h-6" />
                Initiate Refund
              </button>
            )}
        </div>
      </div>
      <div id="receipt" className="p-4">
        <p className=" my-4 text-base text-[#767676] font-medium">
          Payment details
        </p>
        <div className="overflow-hidden border rounded-lg">
          <div className=" bg-[#6687FF1A] border-b border-[#A9B2CF] flex items-center px-8 py-4">
            <div className="flex items-center gap-x-10">
              <p className=" text-[#1B163B] font-semibold text-sm">
                Order ID:{" "}
                <span className=" font-normal ml-2">
                  {transactionInfo?.custom_order_id
                    ? transactionInfo?.custom_order_id
                    : transactionInfo?.collect_id}
                </span>
              </p>
              <p className=" text-[#1B163B] font-semibold text-sm">
                Order Amount:{" "}
                <span className=" font-normal ml-2">
                  {" "}
                  ₹{transactionInfo?.order_amount}
                </span>
              </p>
              <p className=" text-[#1B163B] flex items-center flex-wrap font-semibold text-sm">
                Status:
                <span
                  className={
                    "capitalize  font-normal ml-2 " +
                    (transactionInfo?.status.toLowerCase() === "success"
                      ? "text-[#04B521]"
                      : transactionInfo?.status.toLowerCase() === "failure" ||
                        transactionInfo?.status.toLowerCase() === "failed"
                        ? "text-[#E54F2F]"
                        : transactionInfo?.status.toLowerCase() === "pending"
                          ? "text-yellow-400"
                          : "")
                  }
                >
                  {transactionInfo?.status.toLowerCase()}{" "}
                  {transactionInfo?.isAutoRefund ? (
                    <img className="w-5 h-5 ml-[10px]" src={Aword} alt="a" />
                  ) : null}
                </span>
              </p>
            </div>
            <div className="ml-auto">
              <span className="text-[#1B163B] text-xs font-normal">
                {new Date(
                  transactionInfo?.payment_time || transactionInfo?.createdAt
                ).toLocaleString()}
              </span>
            </div>
          </div>
          <div className="p-8 grid grid-cols-3 w-3/4 gap-6">
            <PaymentSmallCard
              title="Payment method"
              value={transactionInfo?.payment_method?.replaceAll("_", " ")}
            />
            <PaymentSmallCard
              title="Transaction amount"
              value={`₹${transactionInfo?.transaction_amount}`}
            />
            <PaymentSmallCard
              icon={
                transactionInfo?.remarks && (
                  <FaRegEdit
                    onClick={() => {
                      setRemarkEdit(true);
                    }}
                    size={12}
                    className="text-[#717171] cursor-pointer"
                  />
                )
              }
              title="Remarks"
              value={transactionInfo?.remarks}
            />

            <PaymentSmallCard
              title="Reason"
              value={
                transactionInfo?.reason
                  ? extractRemainingPart(transactionInfo.reason)
                  : "NA"
              }
            />

            <PaymentSmallCard
              title="Payment Gateway"
              value={
                transactionInfo?.gateway === "EDVIRON_PG"
                  ? "CASHFREE"
                  : transactionInfo?.gateway === "EDVIRON_EASEBUZZ"
                    ? "EASEBUZZ"
                    : "NA"
              }
            />

            <PaymentSmallCard
              title={
                transactionInfo?.paymentMode === "Net Banking"
                  ? "Bank"
                  : transactionInfo?.paymentMode === "UPI"
                    ? "UPI ID"
                    : transactionInfo?.paymentMode === "Wallet"
                      ? "Provider"
                      : "Bank"
              }
              value={
                getBankName(transactionInfo)
                // transactionInfo?.paymentMode === "Net Banking"
                //   ? transactionInfo?.mode_details !== undefined &&
                //     transactionInfo.mode_details?.netbanking
                //       ?.netbanking_bank_name
                //     ? transactionInfo.mode_details.netbanking
                //       .netbanking_bank_name
                //     : "NA"
                //   : transactionInfo?.paymentMode === "UPI"
                //     ? transactionInfo?.mode_details !== undefined
                //       ? transactionInfo?.mode_details?.upi?.upi_id
                //       : "NA"
                //     : transactionInfo?.paymentMode === "Wallet"
                //       ? transactionInfo?.mode_details !== undefined
                //         ? transactionInfo?.mode_details?.app?.provider
                //         : "NA"
                //       : transactionInfo?.mode_details !== undefined &&
                //         (transactionInfo.paymentMode === "Credit Card" ||
                //           transactionInfo.paymentMode === "Debit Card" ||
                //           transactionInfo.paymentMode === "Credit Card EMI" ||
                //           transactionInfo.paymentMode === "Debit Card EMI")
                //         ? transactionInfo?.mode_details?.card?.card_bank_name
                //         : "NA"
              }
            />
            <PaymentSmallCard title="Processing fee" value="NA" />

            <PaymentSmallCard
              title="Bank reference number"
              value={transactionInfo?.bank_reference}
            />
            <PaymentSmallCard title="Currency" value="INR" />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-8">
          <div className="col-span-1">
            <p className=" my-4 text-base text-[#767676] font-medium">
              User details
            </p>
            <div className="p-8 border rounded-lg grid grid-cols-2 gap-6">
              <PaymentSmallCard
                title="Student name"
                value={transactionInfo?.student_name}
              />
              <PaymentSmallCard
                title="Student Enrollment ID"
                value={transactionInfo?.student_id}
              />
              <PaymentSmallCard
                title="Email"
                value={transactionInfo?.student_email}
              />
              <PaymentSmallCard
                title="Institute Name"
                value={transactionInfo?.school_name}
              />
              <PaymentSmallCard
                title="Phone number"
                value={transactionInfo?.student_phone}
              />

              <PaymentSmallCard
                title="Institute ID"
                value={transactionInfo?.school_id}
              />
            </div>
          </div>
          <div className="col-span-1 relative">
            <p className=" my-4 text-base text-[#767676] font-medium">
              Additional details
            </p>
            <div className="p-8 border h-[80%] rounded-lg grid grid-cols-2 gap-6">
              NA
            </div>
          </div>
        </div>

        {data?.getRefundRequestMerchant.length > 0 && (
          <div className="col-span-2">
            <p className=" my-4 text-base text-[#767676] font-medium">
              Refund details
            </p>

            <_Table
              boxPadding=" 0"
              minHeight={" h-auto"}
              data={[
                ["Sr.No", "Refund ID", "Amount", "Status", "Date", "Action"],
                ...data?.getRefundRequestMerchant?.map(
                  (d: any, index: number) => [
                    index + 1,
                    d?._id,
                    d?.refund_amount,
                    d?.status,
                    <div className="">
                      {new Date(Number(d?.createdAt)).toLocaleString("hi")}
                    </div>,

                    <div>
                      {d?.status === "INITIATED" ? (
                        <AiTwotoneDelete
                          onClick={() => {
                            setDeleteRefundRequest(true);
                            setRefundId(d?._id);
                          }}
                          className="text-[#717171] text-xl cursor-pointer"
                        />
                      ) : (
                        "-"
                      )}
                    </div>,
                  ]
                ),
              ]}
            />
          </div>
        )}
        {/* <AiOutlineLoading3Quarters className=" animate-spin" /> */}

        {/* <div className="grid grid-cols-3 gap-x-8">
          <div className="col-span-3">
            <p className=" my-4 text-base text-[#767676] font-medium">
              Settlement details
            </p>
            <div className="p-8 border rounded-lg w-full">
              <div className=" grid grid-cols-3 w-3/4 gap-6">
                <PaymentSmallCard
                  title="Settlement date & time"
                  value={
                    utrData?.merchantTransactionUtr[0]?.settlementDate &&
                    new Date(
                      utrData?.merchantTransactionUtr[0]?.settlementDate
                    ).toLocaleString("hi")
                  }
                />
                <PaymentSmallCard
                  title="Status"
                  value={utrData?.merchantTransactionUtr[0]?.status}
                />
                <PaymentSmallCard
                  title="UTR number"
                  value={utrData?.merchantTransactionUtr[0]?.utrNumber}
                />
              </div>
            </div>
          </div>
        </div>*/}
      </div>
      {/* <div>
        {data?.merchantOrderRefunds.length ? (
          <_Table
            data={[
              [
                "Date & Time",
                "Refund Amt.",
                "CF Refund ARN",
                "Refund Speed",
                "Status",
              ],
              ...data?.merchantOrderRefunds.map((d: any) => [
                <div>{new Date(d?.created_at).toLocaleString("hi")}</div>,
                <div>{d?.refund_amount}</div>,
                <div>{d?.refund_arn}</div>,
                <div>NA</div>,
                <div>{d?.refund_status}</div>,
              ]),
            ]}
          />
        ) : (
          ""
        )}
      </div> */}

      {transactionInfo?.vendors_info?.length > 0 ? (
        <div>
          <p className="m-4 text-base text-[#767676] font-medium">
            Vendor details
          </p>
          <_Table
            minHeight=" min-h-0"
            boxPadding=" p-0"
            copyRight={false}
            data={[
              ["Sr.No", "Vendor Name", "Vendor ID", "Amount"],
              ...transactionInfo?.vendors_info.map((d: any, i: number) => [
                <div>{i + 1}</div>,
                <div>{d?.name}</div>,
                <div>{d?.vendor_id}</div>,
                <div>
                  {d?.amount
                    ? amountFormat(d?.amount)
                    : amountFormat(
                      (transactionInfo.order_amount * d?.percentage) / 100
                    )}
                </div>,
              ]),
            ]}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TransactionReceipt;
