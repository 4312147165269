import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";
import axios from "axios";
const root = ReactDOM.createRoot(document.getElementById("root"));
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL + "/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("merchantToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(async ({ extensions, message, locations, path }) => {
      if (
        window.location.pathname === "/login" &&
        path?.[0] === "getMerchantQuery"
      ) {
        return;
      } else if (
        window.location.pathname === "/" &&
        path?.[0] === "getMerchantQuery"
      ) {
        return;
      } else if (
        window.location.pathname === "/reset-password" &&
        path[0] === "getMerchantQuery"
      ) {
        return;
      } else {
        const merchantToken = localStorage.getItem("merchantToken");
        
        const config = {
          method: "post",
          url:
            process.env.REACT_APP_BACKEND_URL +
            "/main-backend/send-queryError-mail",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${merchantToken}`,
          },
          data: {
            queryName: path?.[0],
            error: JSON.stringify(extensions),
            message: message,
            merchantToken,
            timestamp: new Date().toISOString(),
          },
        };

        try {
          await axios.request(config);

        } catch (error) {
          console.log(error.message);
        }
        toast.error(message);
      }
    });

  if (networkError) toast.error(networkError);
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
