import { gql } from "@apollo/client";

export const LOG_IN_MERCHANT = gql`
  mutation MerchantLogin($email: String!, $password: String!) {
    merchantLogin(email: $email, password: $password)
  }
`;

export const GET_USER = gql`
  query GetMerchantQuery {
    getMerchantQuery {
      _id
      name
      apiKey
      email_id
      role
      phone_number
      user
      trustee_id
      trustee_logo
      school_id
      school_logo
    }
  }
`;

export const GET_SETTLEMENT_REPORTS = gql`
  query GetMerchantSettlementReports {
    getMerchantSettlementReports {
      settlementAmount
      adjustment
      netSettlementAmount
      fromDate
      tillDate
      status
      utrNumber
      settlementDate
      clientId
    }
  }
`;

export const GET_TRANSACTION_REPORT = gql`
  query GetMerchantTransactionReport(
    $startDate: String
    $endDate: String
    $status: String
    $page: String
    $limit: String
    $isQRCode: Boolean
    $isCustomSearch: Boolean
    $searchFilter: String
    $searchParams: String
    $payment_modes: [String!]
  ) {
    getMerchantTransactionReport(
      startDate: $startDate
      endDate: $endDate
      status: $status
      page: $page
      limit: $limit
      isQRCode: $isQRCode
      isCustomSearch: $isCustomSearch
      searchFilter: $searchFilter
      searchParams: $searchParams
      payment_modes: $payment_modes
    ) {
      total_pages
      current_page
      transactionReport {
        collect_id
        updatedAt
        createdAt
        order_amount
        transaction_amount
        payment_method
        school_name
        school_id
        status
        student_id
        student_name
        student_email
        student_phone
        student_receipt
        bank_reference
        remarks
        details
        isAutoRefund
        isQRPayment
        commission
        custom_order_id
        payment_time
        gateway
        capture_status
        vendors_info {
          vendor_id
          percentage
          amount
          name
        }
      }
    }
  }
`;

export const GET_MERCHANT_SINGLE_TRANSACTION_INFO = gql`
  query GetMerchantSingleTransactionReport($collect_id: String!) {
    getMerchantSingleTransactionReport(collect_id: $collect_id) {
      payment_method
      payment_time
      collect_id
      isAutoRefund
      updatedAt
      createdAt
      order_amount
      transaction_amount
      payment_method
      school_name
      school_id
      status
      student_id
      student_name
      student_email
      student_phone
      student_receipt
      bank_reference
      remarks
      details
      commission
      custom_order_id
      isQRPayment
      reason
      gateway
      vendors_info {
        vendor_id
        percentage
        amount
        name
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetMerchantPass($email: String!, $password: String!) {
    resetMerchantPass(email: $email, password: $password) {
      msg
    }
  }
`;

export const VERIFY_TOKEN = gql`
  query VerifyMerchantResetPassToken($token: String!) {
    verifyMerchantResetPassToken(token: $token) {
      active
    }
  }
`;

export const RESET_MAILS = gql`
  mutation SendResetPassLinkMerchant($email: String!) {
    sendResetPassLinkMerchant(email: $email) {
      active
    }
  }
`;

export const VALIDATE_LOGIN_OTP = gql`
  mutation ValidateMerchantLoginOtp($otp: String!, $email: String!) {
    validateMerchantLoginOtp(otp: $otp, email: $email)
  }
`;

export const VERIFY_PASSWORD_OTP = gql`
  mutation VerifyPasswordOtp($otp: String!, $password: String!) {
    verifyMerchantPasswordOtp(otp: $otp, password: $password)
  }
`;

export const CREATE_MEMBER = gql`
  mutation CreateMember(
    $name: String!
    $email: String!
    $phone_number: String!
    $access: String!
    $password: String!
  ) {
    createMerchantMember(
      name: $name
      email: $email
      phone_number: $phone_number
      access: $access
      password: $password
    )
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($user_id: String!) {
    deleteMerchantMember(user_id: $user_id)
  }
`;

export const GET_ALL_MEMBERS = gql`
  query GetAllMembers {
    getAllMerchantMembers {
      _id
      merchant_id
      name
      email
      phone_number
      access
    }
  }
`;

export const UPDATE_MEMBER_DETAIL = gql`
  mutation UpdateMemberDetails(
    $name: String!
    $user_id: String!
    $email: String!
    $phone_number: String!
  ) {
    updateMerchantMemberDetails(
      name: $name
      user_id: $user_id
      email: $email
      phone_number: $phone_number
    )
  }
`;

export const UPDATE_ACCESS_LEVEL = gql`
  mutation UpdateAccessLevel($user_id: String!, $access: String!) {
    updateMerchantAccessLevel(user_id: $user_id, access: $access)
  }
`;

export const SEND_CHANGE_PASS_OTP = gql`
  mutation SendOtp {
    sendMerchantResetPassOtp
  }
`;

export const UPDATE_MERCHANT_MAIL = gql`
  mutation UpdateMerchantMail($email: String!, $otp: String!) {
    updateMerchantMail(email: $email, otp: $otp)
  }
`;

export const SEND_EDIT_OTP = gql`
  mutation SendOtp {
    sendMerchantEditOtp
  }
`;

export const UPDATE_MERCHANT_PHONE_NUMBER = gql`
  mutation updateMerchantPhoneNumber($phone_number: String!, $otp: String!) {
    updateMerchantPhoneNumber(phone_number: $phone_number, otp: $otp)
  }
`;

export const GET_REFUND = gql`
  query GetOrderRefund($order_id: String!) {
    merchantOrderRefunds(order_id: $order_id) {
      created_at
      order_id
      processed_at
      refund_amount
      refund_arn
      refund_currency
      refund_id
      refund_note
      refund_speed
      refund_status
      refund_type
    }
  }
`;

export const ADD_REMARK = gql`
  mutation AddRemarks($collect_id: String!, $remark: String!) {
    addMerchantRemarks(collect_id: $collect_id, remark: $remark) {
      collect_id
      remarks
    }
  }
`;
export const DELETE_REMARK = gql`
  mutation DeleteRemark($collect_id: String!) {
    deleteMerchantRemark(collect_id: $collect_id)
  }
`;

export const GET_TRANSACTION_UTR = gql`
  query TransactionUtr($date: String!, $school_id: String!) {
    merchantTransactionUtr(date: $date, school_id: $school_id) {
      settlementAmount
      adjustment
      netSettlementAmount
      fromDate
      tillDate
      status
      utrNumber
      settlementDate
      clientId
      trustee
      schoolId
    }
  }
`;

export const GET_ALL_REFUNDS = gql`
  query MerchantRefunds {
    merchantRefunds {
      order_id
      refund_amount
      refund_status
      refund_id
      bank_reference
      created_at
      order_amount
      payment_mode
      pg_refund_id
      processed_at
      refund_arn
      refund_currency
      refund_note
      refund_speed
      refund_type
      school_id
      service_charge
      service_tax
      trustee_id
    }
  }
`;

export const INITIATE_REFUND = gql`
  mutation InitiateRefundRequest(
    $order_id: String!
    $refund_amount: Float!
    $transaction_amount: Float!
    $order_amount: Float!
    $reason: String!
  ) {
    initiateRefundRequest(
      order_id: $order_id
      refund_amount: $refund_amount
      order_amount: $order_amount
      transaction_amount: $transaction_amount
      reason: $reason
    )
  }
`;

export const DELETE_REFUND = gql`
  mutation DeleteRefundRequest($refund_id: String!) {
    deleteRefundRequest(refund_id: $refund_id) {
      trustee_id
      school_id
      order_id
      status
      refund_amount
      order_amount
      transaction_amount
      commission_amount
      reason
    }
  }
`;

export const GET_REFUND_STATUS = gql`
  query GetRefundRequestMerchant($order_id: String!) {
    getRefundRequestMerchant(order_id: $order_id) {
      _id
      trustee_id
      createdAt
      updatedAt
      school_id
      order_id
      status
      refund_amount
      order_amount
      transaction_amount
      custom_id
      split_refund_details {
        vendor_id
        amount
      }
    }
  }
`;

export const GET_REFUND_REQUESTS = gql`
  query GetRefundRequests {
    getRefundRequests {
      _id
      trustee_id
      createdAt
      updatedAt
      school_id
      order_id
      status
      refund_amount
      order_amount
      transaction_amount
      reason
    }
  }
`;

export const GET_VENDORS = gql`
  query GetMerchantVendor(
  $page: Int! 
  $limit: Int!
  $startDate: String
  $endDate: String
  $vendor_id: String
  $custom_id: String
  ) {
    getMerchantVendor(
    page: $page
    limit: $limit
    startDate: $startDate
    endDate: $endDate
    vendor_id: $vendor_id
    custom_id: $custom_id
    ) {
      totalPages
      currentPage
      vendors {
        _id
        trustee_id
        school_id
        name
        email
        phone
        createdAt
        updatedAt
        status
        bank_details {
          account_holder
          account_number
          ifsc
        }
        kyc_info {
          account_type
          business_type
          uidai
          gst
          cin
          pan
          chequeUrl
          passport_number
        }
      }
    }
  }
`;

export const GET_VENDOR_TRANSACTION = gql`
  query GetMerchantVendorTransaction(
    $page: Int!
    $limit: Int!
    $startDate: String
    $endDate: String
    $status: String
    $vendor_id: String
    $custom_id: String
    $order_id: String
  ) {
    getMerchantVendorTransaction(
      page: $page
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      status: $status
      vendor_id: $vendor_id
      custom_id: $custom_id
      order_id: $order_id
    ) {
      vendorsTransaction {
        
        collect_id
        custom_id
        name
        status
        amount
        createdAt
        updatedAt
        transaction_amount
        payment_method
        gateway
        additional_data
        custom_order_id
        schoolName
      }
      totalPages
      limit
      page
    }
  }
`;

export const INITIATE_SPLIT_REFUND = gql`
  mutation InitiateSplitRefund(
    $order_id: String!
    $refund_amount: Float!
    $order_amount: Float!
    $transaction_amount: Float!
    $reason: String!
    $split_refund_details: [SplitRefundDetails!]!
  ) {
    initiateSplitRefund(
      order_id: $order_id
      refund_amount: $refund_amount
      order_amount: $order_amount
      transaction_amount: $transaction_amount
      reason: $reason
      split_refund_details: $split_refund_details
    )
  }
`;

export const GET_MERCHANT_VENDOR_SETTLEMENT_REPORT = gql`
  query getMerchantVendorSettlementReport(
    $page: Int!
    $limit: Int!
    $start_date: String
    $end_date: String
    $utr: String
    $vendor_id: String
  ) {
    getMerchantVendorSettlementReport(
      page: $page
      limit: $limit
      start_date: $start_date
      end_date: $end_date
      utr: $utr
      vendor_id: $vendor_id
    ) {
      totalCount
      page
      totalPages
      limit
      vendor_settlements {
        _id
        school_id
        vendor_id
        trustee_id
        client_id
        utr
        adjustment
        settlement_amount
        net_settlement_amount
        vendor_transaction_amount
        payment_from
        payment_till
        settled_on
        settlement_id
        settlement_initiated_on
        status
        school_name
        vendor_name
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_MERCHANT_VENDOR = gql`
  mutation createMerchantVendor(
    $vendor_info: VendorInfoInput!
    $chequeBase64: String!
    $chequeExtension: String!
  ) {
    createMerchantVendor(
      vendor_info: $vendor_info
      chequeBase64: $chequeBase64
      chequeExtension: $chequeExtension
    )
  }
`;

export const GET_SINGLE_VENDOR_TRANSACTION = gql`
 query GetSingleMerchantVendorTransaction ($order_id : String!) {
    getSingleMerchantVendorTransaction(order_id: $order_id ) {
        _id
        collect_id
        custom_id
        name
        school_id
        status
        amount
        createdAt
        updatedAt
        gateway
        additional_data
        trustee_id
        custom_order_id
        payment_method
        bank_reference
        transaction_amount
        payment_detail
        details
        gateway
    }
}
`;
